import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import {UserStore} from '../../../common/custom-stores/user.store';

@Injectable({ providedIn: 'root' })
export class AuthGuard  {
  constructor(private userStore: UserStore,
              private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.userStore.currentUser;
    if (currentUser) {
      // logged in so return true
      return true;
    } else {
      return this.router.parseUrl('auth/login');
    }
  }
}
