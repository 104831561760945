import {HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from '@angular/common/http';
import { from, Observable, throwError } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { catchError, switchMap } from 'rxjs/operators';
import {AuthService} from '../../modules/auth';
import {UserStore} from '../custom-stores/user.store';
import {Router} from '@angular/router';
import {AuthDataTokenI} from '../interfaces/auth.interface';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private userStore: UserStore,
              private router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let r = req;
    if (this.userStore.token) {
      r = req.clone({
        headers: applyTokensToHeaders(req, this.userStore.token)
      });
    }
    return next.handle(r).pipe(
      catchError((err) => this.handleError(err))
    );
  }


  handleError(error: HttpErrorResponse): Observable<any> {
    return throwError(error);
  }
}


function applyTokensToHeaders(req: HttpRequest<any>, tokens: AuthDataTokenI): HttpHeaders {
  let headers = req.headers;
  if (tokens) {
    headers = tokens.accessToken ? headers.set('authorization', tokens.accessToken) : headers;
    headers = tokens.refreshToken ? headers.set('refresh-token', tokens.refreshToken) : headers;
  }
  return headers;
}
